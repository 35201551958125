import Vue from 'vue';

import store from '../store/index';

export const Cookie = {
  getCookie(name) {
    var arr,
      reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)');
    if ((arr = document.cookie.match(reg))) {
      return unescape(arr[2]);
    } else return null;
  },
  setCookie(cname, cvalue, exdays) {
    if (cname === 'token' && exdays > 0) {
      store.commit('login');
    }
    if (cname === 'userName' && exdays > 0) {
      store.commit('setUserName', cvalue);
    }
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var domain = process.env.VUE_APP_COOKIE_DOMAIN;
    // var domain = null;
    // if (/\.com$/i.test(location.host)) {
    //   domain = location.host.replace(/^[^.]*\./i, '.');
    // }
    var expires = 'expires=' + d;
    console.log(expires);
    document.cookie =
      cname +
      '=' +
      cvalue +
      '; ' +
      expires +
      (domain ? ';domain=' + domain : '') +
      ';path=/';
  }
};
Vue.prototype.$cookie = Cookie;
