import Vue from 'vue';
import Vuex from 'vuex';
import { Cookie } from '../plugins/cookie';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLogin: Cookie.getCookie('token') ? true : false,
    // 账户是否通过审核
    isAccountPassCheck: false,
    // 账户是否有资质
    isAccountHasAuth: false,
    userName: Cookie.getCookie('userName') || '',
    // 营业执照号
    businessLicense: ''
  },
  mutations: {
    // 这里的types坐等有时间的人单独写成常量了。时间紧
    setUserName(state, userName) {
      state.userName = userName;
    },
    setAccountPass(state, isPass) {
      state.isAccountPassCheck = isPass;
    },
    setAccountAuth(state, hasAuth) {
      state.isAccountHasAuth = hasAuth;
    },
    setBusinessLicense(state, businessLicense) {
      state.businessLicense = businessLicense;
    },
    login(state) {
      state.isLogin = true;
      state.userName = Cookie.getCookie('userName');
    },
    logout(state) {
      state.isLogin = false;
      Cookie.setCookie('token', '', -1);
      Cookie.setCookie('userName', '', -1);
      Cookie.setCookie('accountId', '', -1);
      Cookie.setCookie('loginType', '', -1);
    }
  },
  actions: {},
  modules: {}
});
