import vueInstance from '@/main';
import router from '../router';
import store from '../store';

export const ToCDB = str => {
  let tmp = '';
  for (let i = 0; i < str.length; i++) {
    if (str.charCodeAt(i) > 65248 && str.charCodeAt(i) < 65375) {
      tmp += String.fromCharCode(str.charCodeAt(i) - 65248);
    } else {
      tmp += String.fromCharCode(str.charCodeAt(i));
    }
  }
  return tmp;
};

// 单例，这里写的有点问题
let hasPopTokenInvalid = false;
export const tokenInvalid = () => {
  if (hasPopTokenInvalid) {
    return;
  }
  hasPopTokenInvalid = true;
  store.commit('logout');
  vueInstance
    .$confirm('当前登录状态失效', '提示', {
      distinguishCancelAndClose: true,
      closeOnPressEscape: false,
      closeOnHashChange: false,
      closeOnClickModal: false,
      cancelButtonText: '前往首页',
      confirmButtonText: '重新登录',
      type: 'warning'
    })
    .then(() => {
      router.push('/login');
      hasPopTokenInvalid = false;
    })
    .catch(action => {
      hasPopTokenInvalid = false;
      if (action === 'cancel') {
        if (router.currentRoute.path === '/home') {
          location.reload();
        } else {
          router.push('/home');
        }
      }
    });
};

/**
 * 防抖
 * @param {Function} func
 * @param {number} delay
 * @return {*}
 */
export function debounce(func, delay) {
  let timer; // 定义一个变量来保存定时器ID

  return function() {
    clearTimeout(timer); // 清除之前设置的定时器

    timer = setTimeout(() => {
      func.apply(this, arguments); // 调用传入的函数并将参数传递进去
    }, delay);
  };
}
