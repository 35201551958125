import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Layout',
    redirect: '/home',
    component: () => import('../components/layout/Index.vue'),
    children: [
      {
        path: '/home',
        name: 'home',
        component: () => import('../views/home')
      },
      {
        path: '/login',
        name: 'login',
        component: () => import('../views/login/Login.vue')
      },
      {
        path: '/register',
        name: 'register',
        component: () => import('../views/login/Register.vue')
      },
      {
        path: '/recover',
        name: 'recover',
        component: () => import('../views/login/recover.vue')
      },
      {
        path: '/doc',
        name: 'doc',
        component: () => import('../views/doc')
      },
      {
        path: '/contact',
        name: 'contact',
        component: () => import('../views/contactus')
      },
      {
        path: '/publish',
        name: 'publish',
        component: () => import('../views/publish')
      },
      {
        path: '/check',
        name: 'check',
        component: () => import('../views/publish/check.vue')
      },
      {
        path: '/manage',
        name: 'manage',
        component: () => import('../views/manage')
      },
      {
        path: '/manage_submit',
        name: 'manage_submit',
        component: () => import('../views/manage/submit.vue')
      },
      {
        path: '/app_Manage',
        name: 'app_Manage',
        component: () => import('../views/manage/appManage.vue')
      },
      {
        path: '/manage_update',
        name: 'manage_update',
        component: () => import('../views/manage/update.vue')
      },
      {
        path: '/detail/:apkId',
        name: 'detail',
        component: () => import('../views/manage/detail.vue')
      },
      {
        path: '/customize',
        name: 'customize',
        component: () => import('../views/service/customize')
      },
      {
        path: '/big_data',
        name: 'big_data',
        component: () => import('../views/service/big_data')
      },
      {
        path: '/big_data_detail',
        name: 'big_data_detail',
        component: () =>
          import('../views/service/components/big_data_detail.vue')
      },
      {
        path: '/account',
        name: 'account',
        component: () => import('../views/account/edit.vue')
      },
      {
        path: '/account_submit',
        name: 'account_submit',
        component: () => import('../views/account/index.vue')
      },
      {
        path: '/ps_modify',
        name: 'ps_modify',
        component: () => import('../views/account/modify.vue')
      },
      {
        path: '/message',
        name: 'message',
        component: () => import('../views/message')
      },
      {
        path: '/notice',
        name: 'notice',
        component: () => import('../views/message/notice')
      },
      {
        path: '/service_icon',
        name: 'service_icon',
        component: () => import('../views/service/icon')
      },
      {
        path: '/service_video',
        name: 'service_video',
        component: () => import('../views/service/video')
      },
      {
        path: '/check_rule',
        name: 'check_rule',
        component: () => import('../views/doc/check/rule.vue')
      },
      {
        path: '/check_quality',
        name: 'check_quality',
        component: () => import('../views/doc/check/quality.vue')
      },
      {
        path: '/check_measure',
        name: 'check_measure',
        component: () => import('../views/doc/register/measure.vue')
      },
      {
        path: '/check_promise',
        name: 'check_promise',
        component: () => import('../views/doc/check/promise.vue')
      },
      {
        path: '/register_convention',
        name: 'register_convention',
        component: () => import('../views/doc/register/convention.vue')
      },
      {
        path: '/register_threeConvention',
        name: 'register_threeConvention',
        component: () => import('../views/doc/register/threeConvention.vue')
      },
    ]
  }
];

const router = new VueRouter({
  mode: 'hash',
  // base: process.env.BASE_URL,
  routes
});

export default router;
