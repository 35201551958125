import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import './assets/css/reset.css';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

window.$ = require('jquery');

// 引入
import './plugins';

Vue.config.productionTip = false;
Vue.prototype.$sendHttpsRequest = url => {
  const httpsRegex = /^https:\/\//;
  return url && !httpsRegex.test(url);
};
//url跳转
Vue.prototype.$goToBlank = function(type) {
  let url = '';
  if (process.env.NODE_ENV === 'production') {
    url = 'https://dev.eebbk.com/index/#/';
  } else if (process.env.NODE_ENV === 'development') {
    url = 'http://localhost:8088/#/';
  } else {
    url = 'https://h5.eebbk.com/test/dev-platform/#/';
  }
  window.open(url + type, '_blank');
};
Vue.prototype.$goToUrl = function(url) {
  window.open(url, '_blank');
};
export default new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
