import Vue from 'vue';
import axios from 'axios';
import { tokenInvalid } from './util';

import { Cookie } from './cookie';

const StateCodeMap = {
  TOKEN_INVALID: '102',
  // 写在业务代码里的不明code，看作用是token过期，有的不是token过期，***
  TOKEN_INVALID2: '104'
};

axios.defaults.baseURL = process.env.VUE_APP_REQUEST_URL;

export const getCommonHeader = () => ({
  token: Cookie.getCookie('token'),
  userName: Cookie.getCookie('userName')
});

// 添加请求拦截器
axios.interceptors.request.use(
  function(config) {
    config.headers = {
      ...config.headers,
      ...getCommonHeader(),
      'Content-Type': 'application/json'
    };
    // config.withCredentials = true;
    return config;
  },
  function(error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
axios.interceptors.response.use(
  function(response) {
    let data = response.data;
    if (data.stateCode === StateCodeMap.TOKEN_INVALID) {
      tokenInvalid();
    }
    // if (data.stateCode === StateCodeMap.TOKEN_INVALID2) {
    //   tokenInvalid();
    // }
    return response;
  },
  function(error) {
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);

Vue.prototype.$axios = axios;
