<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'ROOT'
};
</script>
<style>
.goToBlank {
  cursor: pointer;
  text-decoration: underline;
}
</style>
